<template>
  <div class="col-span-12 xl:col-span-6">
    <div class="intro-y box">
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          p-5
          border-b border-gray-200
          dark:border-dark-5
        "
      >
        <h2 class="font-medium text-base mr-auto">
          {{ $t('views.proposals.create.title') }}
        </h2>
      </div>
      <div class="p-5 relative">
        <LoadingBlock v-if="processing" />

        <div class="">
          <div class="relative">
            <label for="form-input-1" class="form-label"
              >{{ $t('views.proposals.create.name') }}</label>
            <input
              id="form-input-1"
              type="text"
              class="form-control"
              v-model="proposal.title"
            />
          </div>

          <div class="mt-3 relative">
            <label for="form-input-2" class="form-label"
              >{{ $t('views.proposals.create.description') }}</label
            >
            <input
              id="form-input-2"
              type="text"
              class="form-control"
              v-model="proposal.description"
            />
          </div>

          <div class="mt-3 relative">
            <label for="form-input-3" class="form-label">{{ $t('views.proposals.create.operator') }}</label>
            <input
              id="form-input-3"
              type="text"
              class="form-control"
              v-model="proposal.operator"
            />
          </div>

          <div class="mt-3 relative">
            <label for="form-input-4" class="form-label">{{ $t('views.proposals.create.deposit') }}</label>
            <input
              id="form-input-4"
              type="text"
              class="form-control form-control-lg text-lg"
              placeholder="0"
              v-model="proposal.amount"
              @update:modelValue="calculateFee"
              v-on:keypress="onlyFloat"
            />
            <div class="text-white absolute right-5 top-9 text-lg uppercase">
              {{ proposal.denom }}
            </div>
          </div>

          <div class="mt-5 flex items-center">
            <div class="flex">
              <div class="mr-1">{{ $t('views.proposals.create.fee') }}:</div>
              <div class="text-white font-semibold uppercase">
                {{ fee.amount }} {{ fee.denom }}
              </div>
            </div>
            <div class="ml-auto flex">
              <button
                class="btn btn-secondary w-24 mr-1 mb-2"
                v-on:click="reset"
              >
                {{ $t('views.proposals.create.reset') }}
              </button>
              <button
                class="btn btn-primary w-24 mr-1 mb-2"
                v-on:click="create"
              >
                {{ $t('views.proposals.create.create') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Bech32 } from "@cosmjs/encoding";
import Coin from "@/utils/coin";
import Constants from "@/utils/constants";
import BigNumber from "bignumber.js";
import { Registry } from "@cosmjs/proto-signing";
import { DepositorySetOperatorProposal } from "@/store/generated/metdeal/cryptounce/metdeal.cryptounce.depository.v1/module/types/depository/v1/depository";
import LoadingBlock from "@/components/common/LoadingBlock.vue";

const _ = require("lodash");

export default defineComponent({
  components: {
    LoadingBlock,
  },
  name: "ProposalCreate",
  data: function () {
    return {
      proposal: {
        title: "",
        description: "",
        operator: "",
        amount: "",
        denom: Constants.DEFAULT_DISPLAY_CURRENCY,
      },
      address: "",
      gas: 0,
      fee: { amount: "0", denom: Constants.DEFAULT_DISPLAY_CURRENCY },
      processing: false,
    };
  },
  computed: {
    validForm: function () {
      return (
        this.proposal.title &&
        this.proposal.description &&
        this.validAddress &&
        this.validAmount(this.proposal.amount)
      );
    },
    validAddress: function () {
      let toAddress;
      try {
        toAddress = !!Bech32.decode(this.proposal.operator);
      } catch {
        toAddress = false;
      }
      return toAddress;
    },
  },
  mounted: async function () {
    this.address = this.$store.getters["common/wallet/address"];
  },
  methods: {
    validAmount: function (amount, gtz = false) {
      const res =
        !isNaN(amount) && !isNaN(parseFloat(amount)) && isFinite(amount);
      return gtz ? res && parseFloat(amount) > 0 : res;
    },
    onlyFloat(event, message) {
      if (!/\d/.test(event.key) && (event.key !== "." || /\./.test(message)))
        return event.preventDefault();
    },
    create: async function () {
      if (
        !this.processing &&
        this.validForm &&
        this.gas > 0 &&
        this.validAmount(this.fee.amount, true)
      ) {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        const registry = new Registry([
          [
            "/metdeal.cryptounce.depository.v1.DepositorySetOperatorProposal",
            DepositorySetOperatorProposal,
          ],
        ]);
        const content = {
          typeUrl:
            "/metdeal.cryptounce.depository.v1.DepositorySetOperatorProposal",
          value: {
            title: this.proposal.title,
            description: this.proposal.description,
            denom: Constants.DEFAULT_CURRENCY,
            operator: this.proposal.operator,
          },
        };
        const messageBytes = registry.encode(content);
        const value = {
          content: {
            typeUrl:
              "/metdeal.cryptounce.depository.v1.DepositorySetOperatorProposal",
            value: messageBytes,
          },
          initialDeposit: [coin.toBase(this.proposal.amount)],
          proposer: this.address,
        };
        try {
          this.processing = true;
          const txResult = await this.$store.dispatch(
            "cosmos.gov.v1beta1/sendMsgSubmitProposal",
            {
              value,
              fee: [coin.toBase(this.fee.amount)],
              memo: "",
              gas: this.gas.toString(),
            }
          );
          if (txResult && !txResult.code) {
            this.$toast.success(
              this.$i18n.t(
                "views.proposals.notificationSuccessProposalCreate"
              )
            );
            await this.$store.dispatch(
              "cosmos.gov.v1beta1/QueryProposals",
              { options: {} },
              {}
            );
            this.reset();
          }
        } catch (e) {
          this.$toast.error(this.$i18n.t('common.errorNotification'))
          console.error(e);
        } finally {
          this.processing = false;
        }
      }
    },
    reset: function () {
      this.proposal.title = "";
      this.proposal.description = "";
      this.proposal.operator = "";
      this.proposal.amount = "";
      this.gas = 0;
      this.fee = { amount: "0", denom: Constants.DEFAULT_DISPLAY_CURRENCY };
    },
    calculateFee: _.debounce(async function ($value) {
      if (this.validForm) {
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        const registry = new Registry([
          [
            "/metdeal.cryptounce.depository.v1.DepositorySetOperatorProposal",
            DepositorySetOperatorProposal,
          ],
        ]);
        const content = {
          typeUrl:
            "/metdeal.cryptounce.depository.v1.DepositorySetOperatorProposal",
          value: {
            title: this.proposal.title,
            description: this.proposal.description,
            denom: Constants.DEFAULT_CURRENCY,
            operator: this.proposal.operator,
          },
        };
        const messageBytes = registry.encode(content);
        const value = {
          content: {
            typeUrl:
              "/metdeal.cryptounce.depository.v1.DepositorySetOperatorProposal",
            value: messageBytes,
          },
          initialDeposit: [coin.toBase(this.proposal.amount)],
          proposer: this.address,
        };
        try {
          const simulateResult = await this.$store.dispatch(
            "cosmos.gov.v1beta1/sendMsgSubmitProposalSimulate",
            {
              value,
              fee: [{ amount: "0", denom: Constants.DEFAULT_CURRENCY }],
              memo: "",
            }
          );
          this.gas = simulateResult.calculatedGas;

          let baseAmount = BigNumber(this.gas)
            .multipliedBy(BigNumber(Constants.GAS_PRICE))
            .multipliedBy(BigNumber(10).exponentiatedBy(coin.denomExponent()));
          baseAmount = baseAmount.integerValue(BigNumber.ROUND_CEIL);
          this.fee = coin.toDisplay(baseAmount);
        } catch (e) {
          console.error(e);
        }
      }
    }, 500),
  },
});
</script>
