<template>
  <div
    class="grid grid-cols-12 gap-6 px-5 py-3 items-center relative"
    :class="{ 'bg-gray-200 dark:bg-dark-1': index % 2 == 0 }"
  >
    <LoadingBlock v-if="processing" />

    <div class="col-span-12 xl:col-span-3 flex items-center">
      <!-- 
                возможные статусы:
                1) PROPOSAL_STATUS_DEPOSIT_PERIOD - ожидает депонирования - форма для ввода суммы
                2) PROPOSAL_STATUS_VOTING_PERIOD - период голосования - кнопки проголосовать
                3) PROPOSAL_STATUS_PASSED - предложение принято - вместо кнопок показать значок, что все ок
                4) PROPOSAL_STATUS_REJECTED - предложение отклонено
                5) PROPOSAL_STATUS_FAILED - когда не смогли изменения примениться 
            -->

      <div class="mr-3 bg-gray-600 p-2">
        <component :is="getProposalIcon"></component>
      </div>

      <div class="">
        <div>
          <a
            target="_blank"
            :href="`${blockExplorer}/proposals/${proposal.proposal_id}`"
            >#{{ proposal.proposal_id }}</a
          >
        </div>
        <div class="text-gray-600">{{ statusText }}</div>
      </div>
    </div>

    <div
      class="
        col-span-12
        xl:col-span-3
        lg:flex
        space-y-3
        lg:space-y-0
        items-center
      "
    ><a
          target="_blank"
          :href="`${blockExplorer}/account/${proposal.proposer}`"
          >{{ proposal.proposer }}
        </a></div>

    <div
      class="
        col-span-12
        xl:col-span-6
        lg:flex
        space-y-3
        lg:space-y-0
        items-center
      "
    >
      <div class="">
        <a
          target="_blank"
          :href="`${blockExplorer}/proposals/${proposal.proposal_id}`"
          >{{ proposal.content.title }}
        </a>
      </div>
      <div class="ml-auto">
        <button
          v-if="
            !formActionDepositVisible &&
            proposal.status == 'PROPOSAL_STATUS_DEPOSIT_PERIOD'
          "
          v-on:click="depositToggle"
          class="btn btn-primary ml-2"
        >
          <PlusIcon class="w-4 h-4 mr-2" /> {{ $t('views.proposals.list.deposit') }}
        </button>

        <button
          v-if="
            !formActionVoteVisible &&
            proposal.status == 'PROPOSAL_STATUS_VOTING_PERIOD'
          "
          v-on:click="voteToggle"
          class="btn btn-primary ml-2"
        >
          <ActivityIcon class="w-4 h-4 mr-2" /> {{ $t('views.proposals.list.vote') }}
        </button>
      </div>
    </div>

    <div class="col-span-12" v-if="formActionDepositVisible">
      <div class="text-right">
        <label for="form-input-3" class="form-label">{{ $t('views.proposals.list.depositLabel') }}</label>
        <div class="flex">
          <div class="relative">
            <input
              id="form-input-3"
              type="text"
              class="form-control form-control-lg text-lg"
              placeholder="0"
              v-model="deposit.amount"
            />
            <div class="text-white absolute right-5 top-2 text-lg uppercase">
              {{ deposit.denom }}
            </div>
          </div>
          <button class="btn btn-primary ml-2" @click="depositProcess">
            {{ $t('views.proposals.list.deposit') }}
          </button>
          <button class="btn btn-secondary ml-2" @click="depositToggle">
            <XIcon />
          </button>
        </div>
      </div>
    </div>

    <div class="col-span-12" v-if="formActionVoteVisible">
      <div class="text-right">
        <button
          class="btn btn-success ml-2"
          :disabled="processing"
          v-on:click="voteProcess(VoteOption.VOTE_OPTION_YES)"
        >
          <ThumbsUpIcon class="mr-2" />
          {{ $t('views.proposals.list.voteYes') }}
        </button>
        <button
          class="btn btn-danger ml-2"
          :disabled="processing"
          v-on:click="voteProcess(VoteOption.VOTE_OPTION_NO)"
        >
          <ThumbsDownIcon class="mr-2" />
          {{ $t('views.proposals.list.voteNo') }}
        </button>
        <button
          class="btn btn-warning ml-2"
          :disabled="processing"
          v-on:click="voteProcess(VoteOption.VOTE_OPTION_ABSTAIN)"
        >
          <SlashIcon class="mr-2" />
          {{ $t('views.proposals.list.voteAbstain') }}
        </button>
        <button
          class="btn btn-danger ml-2"
          :disabled="processing"
          v-on:click="voteProcess(VoteOption.VOTE_OPTION_NO_WITH_VETO)"
        >
          <ZapIcon class="mr-2" />
          {{ $t('views.proposals.list.voteVeto') }}
        </button>
        <button class="btn btn-secondary ml-2" @click="voteToggle">
          <XIcon />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Constants from "@/utils/constants";
import Coin from "@/utils/coin";
import BigNumber from "bignumber.js";
import { VoteOption } from "@/store/generated/cosmos/cosmos-sdk/cosmos.gov.v1beta1/module/types/cosmos/gov/v1beta1/gov";
import LoadingBlock from "@/components/common/LoadingBlock.vue";


export default defineComponent({
  name: "ProposalItem",
  components: {
    LoadingBlock
  },
  data: function () {
    return {
      deposit: {
        amount: "",
        denom: Constants.DEFAULT_DISPLAY_CURRENCY,
      },
      VoteOption: VoteOption,
      blockExplorer: Constants.BLOCK_EXPLORER,
      formActionDepositVisible: false,
      formActionVoteVisible: false,
      processing: false,
    };
  },
  props: {
    address: String,
    proposal: Object,
    index: Number,
  },
  computed: {
    statusText: function () {
      return this.proposal.status;
    },
    getProposalIcon: function () {
      switch (this.proposal.status) {
        case "PROPOSAL_STATUS_DEPOSIT_PERIOD":
          return "DollarSignIcon";
        case "PROPOSAL_STATUS_VOTING_PERIOD":
          return "ClockIcon";
        case "PROPOSAL_STATUS_PASSED":
          return "CheckIcon";
        case "PROPOSAL_STATUS_REJECTED":
          return "XIcon";
        case "PROPOSAL_STATUS_FAILED":
          return "AlertTriangleIcon";
        default:
          return "AlertTriangleIcon";
      }
    },
  },
  methods: {
    depositToggle: function () {
      this.formActionDepositVisible = !this.formActionDepositVisible;
    },
    depositProcess: async function () {
      try {
        this.processing = true;
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        const value = {
          proposalId: this.proposal.proposal_id,
          depositor: this.address,
          amount: [coin.toBase(this.deposit.amount)],
        };
        const simulateResult = await this.$store.dispatch(
          "cosmos.gov.v1beta1/sendMsgDepositSimulate",
          {
            value,
            fee: [{ amount: "0", denom: Constants.DEFAULT_CURRENCY }],
            memo: "",
          }
        );
        let baseAmount = BigNumber(simulateResult.calculatedGas)
          .multipliedBy(BigNumber(Constants.GAS_PRICE))
          .multipliedBy(BigNumber(10).exponentiatedBy(coin.denomExponent()));
        baseAmount = baseAmount.integerValue(BigNumber.ROUND_CEIL);
        const fee = coin.toDisplay(baseAmount);

        const txResult = await this.$store.dispatch(
          "cosmos.gov.v1beta1/sendMsgDeposit",
          {
            value,
            fee: [coin.toBase(fee.amount)],
            memo: "",
            gas: simulateResult.calculatedGas.toString(),
          }
        );
        if (txResult && !txResult.code) {
          this.$toast.success(
            this.$i18n.t(
              "views.proposals.notificationSuccessProposalDeposited"
            )
          );
          await this.$store.dispatch(
            "cosmos.gov.v1beta1/QueryProposals",
            { options: {} },
            {}
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.formActionDepositVisible = false;
        this.deposit.amount = "";
        this.processing = false;
      }
    },
    voteToggle: function () {
      this.formActionVoteVisible = !this.formActionVoteVisible;
    },
    voteProcess: async function (option) {
      try {
        this.processing = true;
        const coin = new Coin(this.$store, Constants.DEFAULT_CURRENCY);
        const value = {
          proposalId: this.proposal.proposal_id,
          voter: this.address,
          option: option,
        };
        const simulateResult = await this.$store.dispatch(
          "cosmos.gov.v1beta1/sendMsgVoteSimulate",
          {
            value,
            fee: [{ amount: "0", denom: Constants.DEFAULT_CURRENCY }],
            memo: "",
          }
        );
        let baseAmount = BigNumber(simulateResult.calculatedGas)
          .multipliedBy(BigNumber(Constants.GAS_PRICE))
          .multipliedBy(BigNumber(10).exponentiatedBy(coin.denomExponent()));
        baseAmount = baseAmount.integerValue(BigNumber.ROUND_CEIL);
        const fee = coin.toDisplay(baseAmount);

        const txResult = await this.$store.dispatch(
          "cosmos.gov.v1beta1/sendMsgVote",
          {
            value,
            fee: [coin.toBase(fee.amount)],
            memo: "",
            gas: simulateResult.calculatedGas.toString(),
          }
        );
        if (txResult && !txResult.code) {
          this.$toast.success(
            this.$i18n.t(
              "views.proposals.notificationSuccessProposalVote"
            )
          );
          await this.$store.dispatch(
            "cosmos.gov.v1beta1/QueryProposals",
            { options: {} },
            {}
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.formActionVoteVisible = false;
        this.processing = false;
      }
    },
  },
});
</script>
