<template>
  <div class="col-span-12">
    <div class="intro-y box">
      <div
        class="
          flex flex-col
          sm:flex-row
          items-center
          p-5
          border-b border-gray-200
          dark:border-dark-5
        "
      >
        <h2 class="font-medium text-base mr-auto">{{ $t('views.proposals.list.title') }}</h2>
      </div>

      <div class="grid grid-cols-12 gap-6 my-5 px-5">
        <div class="hidden xl:block col-span-12 xl:col-span-3">
          {{ $t('views.proposals.list.id') }}
        </div>
        <div class="hidden xl:block col-span-12 xl:col-span-3">{{ $t('views.proposals.list.proposer') }}</div>
        <div class="hidden xl:block col-span-12 xl:col-span-6">{{ $t('views.proposals.list.name') }}</div>
      </div>

      <ProposalItem
        v-for="(proposal, index) in proposals"
        v-bind:key="index"
        :address="address"
        :index="index"
        :proposal="proposal"
      />

      <div class="text-center gap-6 my-5 px-5">
        <a class="btn btn-secondary w-38 mr-1 mb-2" target="_blank" :href="`${blockExplorer}/proposals`">
              {{ $t('views.proposals.list.showAllProposals') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ProposalItem from "@/components/proposals/ProposalItem.vue";
import Constants from '@/utils/constants'


export default defineComponent({
  name: "ProposalsList",
  components: {
    ProposalItem,
  },
  data: function () {
    return {
      address: "",
      blockExplorer: Constants.BLOCK_EXPLORER
    };
  },
  computed: {
    proposals: function () {
      const list = this.$store.getters["cosmos.gov.v1beta1/getProposals"]({
        params: {},
      })
      // console.log(list);
      const proposals = list.proposals?.filter((p) => p.status == 'PROPOSAL_STATUS_DEPOSIT_PERIOD' || p.status == 'PROPOSAL_STATUS_VOTING_PERIOD').sort((a, b) => b.proposal_id - a.proposal_id)
      return proposals
    },
  },
  mounted: async function () {
    this.address = this.$store.getters["common/wallet/address"];
    await this.$store.dispatch(
      "cosmos.gov.v1beta1/QueryProposals",
      { options: {} },
      {}
    );
  },
});
</script>
