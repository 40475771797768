<template>
  <div class="grid grid-cols-12 gap-6 mt-5">
    <ProposalCreate />
    <ProposalsList />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ProposalCreate from "@/components/proposals/ProposalCreate.vue";
import ProposalsList from "@/components/proposals/ProposalsList.vue";

export default defineComponent({
  components: {
    ProposalCreate,
    ProposalsList,
  },
  mounted: function () {
    this.$store.commit("updateTopBarTitle", this.$i18n.t("menu.proposals"));
  },
});
</script>
